import dateFormat from 'dateformat';

export const isNullOrUndefined = <T>(val: T): boolean => {
  return val === null || val === undefined;
};

export const isNullOrEmpty = <T>(items: T[]): boolean => {
  return isNullOrUndefined(items) || items.length === 0;
};

export const isNullOrWhiteSpace = (value: string): boolean => {
  return isNullOrUndefined(value) || value.trim() === '';
};

export const isEmptyInputValue = (value: any): boolean => {
  return isNullOrUndefined(value) || value.length === 0;
};

export const formatIsoStringSimple = (isoString: string): string => {
  const date = new Date(isoString);
  return dateFormat(date, 'dd mmm yyyy');
};

export const formatDate = (date: Date): string => {
  return dateFormat(date, 'dd mmm yyyy');
};

export const getLinkedInUrl = (): string => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

export const blurFocussedElement = (): void => {
  const buttonElement = document.activeElement as HTMLElement; // Get the currently focused element
  buttonElement.blur();
}

export const formatIsoString = (isoString: string, includeTime = false): string => {
  const date = new Date(isoString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  const day = date.getDate();
  let daySuffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = 'st';
  } else if (day === 2 || day === 22) {
    daySuffix = 'nd';
  } else if (day === 3 || day === 23) {
    daySuffix = 'rd';
  }
  const formattedDate = `${date.toLocaleString('default', { month: 'short' })} ${day}${daySuffix} ${date.getFullYear()}`;
  if (includeTime) {
    return `${formattedDate} ${formattedHours}:${minutes}${ampm}`;
  }
  return formattedDate;
};

export function roundToOneDecimalPlace(num: number): number {
  return Math.round(num * 10) / 10;
}

export function handleGraphQLErrors(errors: any[] | undefined): void {
  if (errors && errors.length > 0) {
    errors.forEach((error) => {
      console.error(error);
    });
    throw errors[0];
  }
}
